@import 'base';

@import "product";
@import "contact";

#header {
	background:#FFF;
	z-index: 1;
	position: relative;
	.header-top{
		background: #FFF;
		padding-bottom: 10px;
		border-bottom: none;
		@include md {
			border-bottom:1px solid #bfbfbf ;
			}
		}
	.header-nav .user-info{
	
		}
	}
.mobile__header{
	margin-top: 18px;
	@include md {
		padding-top: 0px;
		}
	}
#_mobile_logo > h1 > a > img {
	margin-top: 8px;
	}
.material-icons {
	font-size: 28px;
	}
#header .header-nav .blockcart{
	background: $blue;
	color: #FFF;
	}
#header .header-nav .cart-preview .shopping-cart{
	color: #FFF;
	}
#products .thumbnail-container, .featured-products .thumbnail-container, .product-accessories .thumbnail-container, .product-miniature .thumbnail-container{
	box-shadow: none;
	}
//Overrides
#block_myaccount_infos > p > a {
	font-size: 1.125rem;
	}
#wrapper{
	box-shadow: none;
	background: #FFF;
	}
#header .header-top a[data-depth="0"] {
	text-transform: none;
	color: #000;
	}

.top-menu {
	line-height: 40px;
	margin: 0px;
	}
//-----

.card{
	box-shadow: none;
	}

#_desktop_contact_link {
	img{
		margin-right: 0.5rem;
		}
	}
#index{
	#wrapper{
	background: #f1f1f1;
		}
	}
.headerBg{
	background:#FFF ;
	position: absolute;
	top: 0px;
	height: 320px;
	width: 100%;
	left: 0px;
	z-index: 0;
	}
.header-top{
	border-bottom: 1px solid #bfbfbf;
	}
#header .header-top{
	border-bottom: none;
	}
.homePage__menuBoxContainer{
	top: -100px;
	position: relative;
	padding: 0px;
	max-width: 1360px;
	}

.homePage__menuBox{
	cursor: pointer;
	height:140px;
	border-top:10px solid #2fb5d2 ;
	background:#efe6dd ;
	font-size: 1.6rem;
	font-family: $titleFont;
	display: flex;
	justify-content: center;
	font-weight: 600;
	align-items: center;
	text-align: center;
	line-height: 2rem;
	&.off{
		background: #ffffff;
		border-top:10px solid #f4af6c ;
		}
	}

.products{
	padding: 0px 15px;
	}
.product-price-and-shipping > .price {
	font-size: 1.5rem;
	font-family: $titleFont;
	}
.products-section-title{
	font-family: $titleFont;
	font-size: 2.25rem;
	top: -15px;
	position: relative;
	height: 30px;
	}
.h3.product-title{
	font-size: 1.125rem;
	font-family: $titleFont;
	}
#footer {
	padding-top: 0px;
	}

.about__shop__title {
	font-family: $titleFont;
	font-weight: 700;
	@include md{
		font-size: 2.250em;
		}
	font-size: 2em;
	}

.block_newsletter {
	border-top: 10px solid #f4af6c;
	}

.about__shop {
	background: #3e352c;
	color: #FFF;
	padding-top: 75px;
	text-align: center;
	padding-bottom: 50px;

	@include md {
		text-align: left;
		padding-bottom:0px;
		}
	
	}

.about__shop__txt {
	color: #FFF;
	font-size: 1.125em;
	margin-top: 25px;
	font-weight: 400;
	font-family: $titleFont;
	line-height: initial;
	max-width: 585px;
	text-align: center;
	margin-bottom: 40px;
	padding: 0px 30px;
	@include md {
		text-align: left;
		padding: 0px;
		}
	}
.about__shop__icon {
	text-align: center;
	}

.about__shop__icon__img {
	height: 60px;
	display: flex;
	align-items: center;
	justify-content: center;
	}

.about__shop__shortTxt {
	color: #f4af6c;
	font-size: 0.938em;
	text-align: center;
	}

.block_newsletter {
	padding: 40px 0px;
	@media (max-width: 991.98px) {
		padding: 40px 15px;
		}
	}

#block-newsletter-label {
	display: flex;
	}

.block-newsletter-label__txt {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-size: 0.7rem;
	
	@media (max-width: 991.98px) {
		margin-bottom: 20px;
		line-height: 1em;
		}
	span {
		font-size: 1.25rem;
		@media (max-width: 991.98px) {
			line-height: 1.25rem;
			margin-bottom: 10px;
			}

		
		}
	}

.newsletterInput {
	max-width: 490px;
	float: right;
	@media (max-width: 991.98px) {
		float: none;
		}
	}

.newsletterButton {
	font-weight: 400;
	font-size: 1em;
	}

.footer-container {
	background: #f1f1f1;
	box-shadow: none;
	padding: 60px 0px;
	margin-top: 0px;
	}

.copyright {
	background: #FFF;
	height: 105px;
	color: #756f6a;
	font-size: 0.875em;
	line-height: 105px;
	p {
		margin: 0px;
		}
	}
.block-contact__info{
	text-align: center;
	}
.block-contact-title {
	font-weight: bold;
	font-size: 1.125em;
	}

.block-contact {
	font-size: 1em;
	}

.block-contact.links {
	display: flex;
	justify-content: space-between;
	@media (max-width: 991.98px) {
		display: block;
		}
	}
.footer__logo{
	@media (max-width: 991.98px) {
		width: 100%;
		}
	}
.footer__info{
	@media (max-width: 991.98px) {
		width: 100%;
		}
	}
.text-sm-center{
	@media (max-width: 991.98px) {
		text-align: center;
		}
	}