@import "base";

.current-price {
	font-size: 2.25rem;
	font-family: $titleFont;
	font-weight: bold;
	color:$red;
	display: flex;
	align-items: baseline;
	}
.regular-price{
	font-size: 1.125rem;
	}
.product__title{
	font-family: $titleFont;
	font-size: 2.25rem;
	color: #3b342f;
	margin-bottom: 0.5em;
	}
.product-prices{
	@include md{
		float: left;
		}

	}
.has-discount .discount{
	background: $red;
	max-height: 25px;
	width: 50px;
	padding: 0px;
	text-align: center;
	line-height: 23px;
	margin-right: 15px;
	}
.product-price__block{
	height: 100px;
	@include md{
		display: flex;
		justify-content: space-between;
		background: #f1f1f1;
		height: 68px;
		padding: 12px 12px;
		}
	}
.social-sharing{
margin-bottom: 20px;
	}
.product_add_to_cart{
	@include md{
	display: block;
		
		}
	}
.product-add-to-cart-mobile{
	display: none;
	}
.product-prices{
	margin-top: 0px;
	}