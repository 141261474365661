html {
	font-family: 'Noto Sans', sans-serif;
	//font-family: 'Playfair Display', serif;
	}

$titleFont: 'Playfair Display', serif;
$red: #c31b3e;
$blue: #2fb5d2;

@media (min-width: 1200px) {
	.container {
		max-width: 1440px;
		width:auto;
		}
	}

@media (min-width: 768px) {
	.search-widget {
		min-width: 30.63rem;
		}
	}
$screen-sm-min: 576px;
$screen-md-min: 768px;
@mixin md {
	@media (min-width: #{$screen-md-min}) {
		@content;
		}
	}
@mixin sm {
	@media (min-width: #{$screen-sm-min}) {
		@content;
		}
	}