.contact__title{
	margin-bottom: 40px;
	}
#map {
	height: 400px;
	width: 100%;
	margin-top: 60px;
	float: left;
	}
.contact__map{
	width: 100%;
	h1{
		font-family: $titleFont;
		font-size: 2.25rem;
		font-weight: bold;
		}
	h2{
		font-size: 1.125em;
		font-weight: bold;
		}
	}
